import React from 'react'
import CtaButton from './CtaButton'
import Nikhil from "../../Assets/Images/nik01.jpeg";
import Farman from "../../Assets/Images/farman-khan.jpg"
import DrGupta from "../../Assets/Images/dr-gupta.jpeg"
import Shreedhar from "../../Assets/Images/Shreedhar.jpeg"

const speaker = [
    {
        "img": Nikhil,
        'name': 'Mr. Nikhil Sachan',
        'designation': 'Founder & CEO Careerkick Services & Ntechzy',
        'detail': `Nikhil Sachan is the visionary founder of India’s largest college counseling company, Careerkick Services. Over the past 7 years, he has personally guided and helped over 2 lakh students secure admissions into top medical and BAMS colleges across the country. His expertise and dedication have made him a well-known figure in the medical education industry.
Beyond his contributions to education, Nikhil is also a leading personality in the tech world. As the founder of NTechzy, a global tech company, he has made significant strides in delivering cutting-edge solutions to clients worldwide. His dual expertise in education and technology makes him a powerful mentor who understands both academic excellence and innovative thinking.`
    },
    {
        "img": Farman,
        'name': 'Mr. Farman Khan',
        'designation': 'Founder & CEO, Chalk Talk YouTube Channel',
        'detail': `Mr. Farman Khan is the Founder and CEO of Chalk Talk, a leading YouTube channel dedicated to BAMS students. Through his channel, he provides valuable guidance on college selection, BAMS curriculum insights, and Ayurveda awareness. His content has become a go-to resource for students seeking clarity and direction in their BAMS journey. At Siksha Samagam 2.0 (2024), Mr. Khan will share his expertise to help aspirants make informed decisions about their future in Ayurveda.`
    },
    {
        "img": DrGupta,
        'name': 'Dr. Anuruddh Gupta',
        'designation': 'Vice Principal & HOD, Shalya Tantra',
        'detail': `Dr. Anuruddh Gupta is a renowned expert in Ayurveda, specializing in Shalya Tantra. As the Vice Principal and Head of the Shalya Tantra Department at Vimla Family Ayurveda Medical College in Kanpur, he has played a key role in educating future Ayurvedic surgeons. With extensive experience in the field, Dr. Gupta is dedicated to advancing Ayurvedic practices and guiding students toward successful careers in this ancient science. His expertise will provide valuable insights for BAMS aspirants at Siksha Samagam 2.0 (2024).`
    },
    {
        "img": Shreedhar,
        'name': 'Dr. Shridhar Lakkundi',
        'designation': 'Professor, B.A.M.S, M.D (Panchakarma)',
        'detail': `Dr. Shridhar Lakkundi is a respected professor specializing in Panchakarma, one of the key areas of Ayurvedic medicine. With a B.A.M.S and an M.D in Panchakarma, Dr. Lakkundi brings extensive knowledge and experience to the field. His expertise in traditional Ayurvedic therapies makes him a valuable resource for students pursuing a career in Ayurveda. At Siksha Samagam 2.0 (2024), Dr. Lakkundi will offer deep insights into Panchakarma and its significance in modern healthcare.`
    },
]

const GuestSpeaker = () => {
    return (
        <React.Fragment>
            <div className="flex flex-col mx-auto text-white w-full sm:w-[80%]">

                <h3 className='custom-headings'>
                    MEET OUR <span className='green-text'>GUEST</span> SPEAKERS
                </h3>

                <div className='speaker-wrapper'>
                    {speaker.map((item, index) => {
                        return index % 2 == 0 ? <div className='speaker-card-even'>
                            <div className='left'>
                                <p className='speaker-name'>{item.name}</p>
                                <p className='speaker-designation'>{item.designation}</p>
                                <p className='speaker-details'>
                                    {item.detail}
                                </p>

                            </div>
                            <div className='right'>
                                <img src={item.img} alt={item.name} className='speaker-img' />
                            </div>
                        </div> : <div className='speaker-card-odd'>
                            <div className='left'>
                                <img src={item.img} alt={item.name} className='speaker-img' />
                            </div>
                            <div className='right'>
                                <p className='speaker-name'>{item.name}</p>
                                <p className='speaker-designation'>{item.designation}</p>
                                <p className='speaker-details'>
                                    {item.detail}
                                </p>
                            </div>
                        </div>

                    })}
                </div>

                <div className='speaker-wrapper-mobile'>
                    {speaker.map((item, index) => {
                        return  <div className='speaker-mobile-card-even'>
                            <div className='right'>
                                <img src={item.img} alt={item.name} className='speaker-mobile-img' />
                            </div>
                            <div className='left'>
                                <p className='speaker-mobile-name'>{item.name}</p>
                                <p className='speaker-mobile-designation'>{item.designation}</p>
                                <p className='speaker-mobile-details'>
                                    {item.detail}
                                </p>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <CtaButton
                text=' BOOK YOUR SEAT FOR FREE!'
                link='#'
                helpText=''
            />
        </React.Fragment>
    )
}

export default GuestSpeaker